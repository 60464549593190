export const FirebaseConfig = {
	"projectId": "garahex-b714d",
	"appId": "1:1032571442885:web:c958957a7e5ce833ff82ac",
	"databaseURL": "https://garahex-b714d-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "garahex-b714d.appspot.com",
	"locationId": "asia-east2",
	"apiKey": "AIzaSyDzejpIZojykN6xfnWeGBT5WN8Sps1pN3Y",
	"authDomain": "garahex-b714d.firebaseapp.com",
	"messagingSenderId": "1032571442885",
	"measurementId": "G-33NNTF0H5R"
};