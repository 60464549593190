export const colors = {
  LandingPage_Background: '#FFFFFF',
  LandingPage_Description: '#373737',
  Button_Primary: '#46F34D',
  Button_Secondary: '#199dd1', 
  Header_Background: "#FFFFFF",
  Header_Content: '#000000',
  Black: '#000000',
  Header: '#383C3D',
  Header_Text: "#FFFFFF",
  Header_Text_back: "#435152",
  Table_Header: '#BFCACD',
  Action_Button_Back: '#0A6AAD',
  ALERT_BUTTON_GOTO_COLOR: "#c6e2f6",
  ALERT_BUTTON_CLOSE_COLOR:  "#ea969d",
  HEADER: '#2a383b',
  BORDER_BACKGROUND: "#d6d6d6",
  CAR_BOX_EVEN:'#a0ebce',
  CAR_BOX_ODD:'#19b3f5',
  CARD_LABEL:"#4C5152",
  CARD_DETAIL:"#F5F5F5",
  STATUS_COMPLETE:"#2CDE3A",
  STATUS_CANCELED:"#DF2E38",
  STATUS_PENDING:"##FFCD4B",
  ROW_SELECTED:"#EEE",
  TABLE_BORDER:"rgba(224, 224, 224, 1)",
  SUB_2:"#019b9d",
  WALLET_CARD:"#CC3372",
  WALLET_CARD_SHADOW:"#9E9E9E",
  FACEBOOK_COLOR:"#3b5998",
  BLUE:"#00acee",
  

TAXIPRIMARY: "#06113C",
TAXISECONDORY: "#DDDEEE",

DELIVERYPRIMARY: "#0047AB",
DELIVERYSECONDORY: "#E7F1F9",

BIDTAXIPRIMARY: "#00564c",
BIDTAXISECONDORY: "#eee7ca",

TRANSPARENT: "transparent",
WHITE: "#ffffff",
BLACK: "#000000",
RED: "#FF1E00",
GREEN: "#0CAB03",
YELLOW: "#FFDE59 ",
VIOLET:"#A755C2",
FOOTERTOP: "#cccccc",
}